import axios from 'axios'
const baseURL = '//admin.les-3e.ch/api'

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const apiCall = ({ url, data, method }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        if (method == 'get') {
          resolve(axios[method](baseURL + url))
        } else if (method == 'post') {
          resolve(axios[method](baseURL + url, data))
        } else if (method == 'put') {
          resolve(axios[method](baseURL + url, data))
        } else if (method == 'patch') {
          resolve(axios[method](baseURL + url, data))
        }
      } catch (err) {
        reject(new Error(err))
      }
    }, 1000)
  })

export default apiCall
